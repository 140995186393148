


























































import { Vue, Component } from "vue-property-decorator";
import MainMap from "@/views/incident/subComponents/map/historyMap.vue";
import { Input, Button, Table, TableColumn, DatePicker } from "element-ui";
import pagination from "@/components/UIComponents/Pagination.vue";
import Rightdash from "@/views/incident/rightdash.vue";

import { IncidentModule, MetaModule as metaStore } from "@/store/modules";

import { AdminRouter } from "@/utils/routePathContsant";
import { eventHub } from "@/utils/eventHub";

@Component({
  components: {
    DatePicker,
    MainMap,
    Input,
    Button,
    Table,
    TableColumn,
    pagination,
    Rightdash,
  },
})
export default class DutyOfficeClass extends Vue {
  palikas: any[] = [];
  qDutyOffice: any[] = [];
  dutyOfficeList: any[] = [];
  districtName: any = [];
  filter = {
    name: "",
    district: "",
    palika: "",
  };
  datePicker: string = "";
  vehicleSearchOptions: any[] = [] as any;

  historySearch: {
    route: number;
    vehicleId: number;
    date: string;
  } = {} as {
    route: number;
    vehicleId: number;
    date: string;
  };

  get AdminRouter() {
    return AdminRouter;
  }

  get routePath() {
    return AdminRouter;
  }
  get districts() {
    return metaStore.districtList;
  }

  get routes() {
    return metaStore.routesList;
  }
  changeTab(e: any) {
    IncidentModule.setSelectedIncidentTab(e);
  }
  created() {
    metaStore.loadRoutesDD();
    metaStore.loadVehicleListDD();

    if (this.$route.query.route) {
      this.$set(this.historySearch, "route", Number(this.$route.query.route));
    }

    if (this.$route.query.vehicleId) {
      this.$set(
        this.historySearch,
        "vehicleId",
        Number(this.$route.query.vehicleId)
      );
      this.vehicleSearchOptions = metaStore.vehicleList.filter(
        (vehicle) => vehicle.id == this.historySearch.vehicleId
      );
    }

    if (this.$route.query.date) {
      this.$set(this.historySearch, "date", this.$route.query.date as string);
    }
  }

  loadTraccarHistory() {
    eventHub.$emit("load-traccar-history", this.historySearch);
    this.$router.replace({
      name: "HistoryMap",
      query: this.historySearch as any,
    });
  }

  findAllVehicle(query: any) {
    if (query !== "") {
      const vehicles = metaStore.vehicleList;
      // setTimeout(() => {
      this.vehicleSearchOptions = vehicles.filter((vehicle) => {
        return (
          vehicle.vehicleNumber.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });
      // }, 200);
    } else {
      this.vehicleSearchOptions = [];
    }
  }

  edit(id: any) {
    this.$router.push(AdminRouter.GPSServiceRegister + "?id=" + id);
  }
  async filterPalika() {
    this.filter.palika = "";
    this.palikas = await metaStore.loadMuncipality(
      parseInt(this.filter.district)
    );
  }
  filterDutyOffice() {
    // var filterData = dutyOfficeStore.dutyOfficeList;
    // console.log(filterData);
    // if (this.filter.district !== "") {
    //   filterData = filterData.filter(
    //     (x) => x.DistrictId == parseInt(this.filter.district)
    //   );
    // }
    // if (this.filter.name !== "") {
    //   this.filter.name = this.filter.name.toLowerCase();
    //   filterData = filterData.filter((x) =>
    //     x.Description.toLowerCase().includes(this.filter.name)
    //   );
    // }
    // if (this.filter.palika !== "") {
    //   filterData = filterData.filter(
    //     (x) => x.PalikaId == parseInt(this.filter.palika)
    //   );
    // }
    // this.dutyOfficeList = filterData;
  }
  clearFilter() {
    this.filter = {
      name: "",
      district: "",
      palika: "",
    };
    //this.dutyOfficeList = dutyOfficeStore.dutyOfficeList;
  }
  submit(e: any) {
    //dutyOfficeStore.registerDutyOffice();
  }
}
